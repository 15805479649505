import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import {
  H1,
  Paragraph,
  ContentContainer,
  UnorderedList,
  ListItem,
  ExternalLink,
} from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Föräldrainsats" />
      <Layout>
        <ContentContainer>
          <H1>Föräldrainsats på Brandbilen</H1>
          <Paragraph>
            Att själv vara aktiv i förskolan som extraresurs en eller ett antal
            hel eller halvdagar varje år är inget krav men något som många
            föräldrar ser som en stor positiv del av att ha sitt barn på vår
            förskola.
          </Paragraph>
          <Paragraph>
            Andra insatser man får engagera sig i kan vara styrelseuppdrag,
            praktiska göromål, utestäd varje vår och höst (när alla familjer
            samlas), städning av föreskolelokalen m.m.
          </Paragraph>
          <Paragraph>
            För att få arbeta som extraresurs behöver följande krav vara
            uppfyllda:
          </Paragraph>
          <UnorderedList>
            <ListItem>
              <ExternalLink href="https://polisen.se/Service/Belastningsregistret-begar-utdrag/registerutdrag-arbeta-inom-skola-forskola/">
                Registerutdrag
              </ExternalLink>{" "}
              (klicka på länk för att ansöka) lämnat till faddergruppen, det får
              max vara 12 månader gammalt.
            </ListItem>
            <ListItem>
              Tagit del av krav på tystnadsplikt för information eller
              personliga förhållanden som man får kännedom om i samband med sin
              föräldrainsats. Bekräftelse på detta görs genom att signera ett
              dokument om tystnadsplikt.
            </ListItem>
          </UnorderedList>
          <Paragraph>
            Dessa krav gäller givetvis även vår fast anställda personal och våra
            vikarier.
          </Paragraph>
        </ContentContainer>
      </Layout>
    </>
  )
}

export default Page
